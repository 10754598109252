<template>
	<div>
		<b-card>
			<div class="flex">
				<p class="w-1/3">카페24 상품번호</p>
				<input class="form-control -mt-1.5" v-model="input['cafe24ProductNum']" placeholder="" type="number" required :disabled="isEdit"/>
				<b-button class="w-40 ml-3 px-4 -mt-1.5" @click="save" variant="info">{{ isEdit ? '수정' : '등록' }}</b-button>
			</div>
			<div class="flex mt-4 -mb-2">
				<p class="w-1/3">네이버 검색 키워드</p>
				<input class="form-control -mt-1.5" v-model="input['keyword']" placeholder="" type="text" @keyup.enter="searchKeyword"/>
				<b-button class="w-40 ml-3 px-4 -mt-1.5" @click="searchKeyword">검색</b-button>
			</div>
		</b-card>

		<b-card class="mt-4">
			<relateProductSelectList :itemList="res" :pr="ths"/>
		</b-card>
	</div>
</template>

<script>

import { postApi } from "libs/axios";
import relateProductSelectList from "pages/relateProductSelectList";

let ths
export default {
	components: { relateProductSelectList },
	props: { relateProductSeq: { default: '' } },
	data() {
		return this.returnData()
	},
	methods: {
		returnData() {
			return {
				isEdit: this.relateProductSeq != '',
				input: {
					cafe24ProductNum: '',
					keyword: '',
				},
				res: {},
			}
		},
		searchKeyword() {
			if(!this.input.keyword)
				return alert.w('키워드를 입력해주세요')
			postApi( 'naver/shoppingSearch/' + encodeURI( this.input.keyword ) ).then(v=>{
				ths.res = v
				setTimeout(()=>{
					ths.relateProductSelectList.initMount()}, 100)

			})
		},
		save() {
			const inp = this.input,
				cafe24ProductNum = inp.cafe24ProductNum,
				selectedItem = this.relateProductSelectList.getSelectedItem()
			if(!cafe24ProductNum)
				return alert.w('카페24 상품번호를 입력해주세요')
			if(!selectedItem.length)
				return alert.w('관련상품을 선택해주세요')
			postApi('naver/insertRelateProduct', { selectedItem, cafe24ProductNum }).then(v=>{
				alert.s(`${ths.isEdit ? '수정' : '등록'}이 완료되었습니다`)
			})
		}
	},
	created() {
		ths = this
		//postApi( 'member/t' );
		if(this.isEdit) {
			layout.pageTitle = '관련 상품 가격 수정'
			const cafe24ProductNum = this.relateProductSeq
			this.input.cafe24ProductNum = cafe24ProductNum
			postApi( 'naver/selectRelateProduct', { cafe24ProductNum } ).then(v=>{
				ths.relateProductSelectList.init(v)
			})
		}
	},
	mounted() {
		if( !this.isEdit )
			ths.relateProductSelectList.init()
	}
}
</script>
